<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_MEMBERSHIP_STATUS')" type="back" :settings="false" />
    </template>

    <NoDataFoundComponent :size="'sm'" />
  </f7-page>
</template>

<script>
import { defineComponent, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));

export default defineComponent({
  name: "MembershipPage",
  components: {
    ProfileNavigationComponent,
    NoDataFoundComponent,
  },
  props: { f7router: Object },
  setup(props) {

    const store = useStore();

    return {};
  },
});
</script>

<style>
.empty-container {
  margin-top: 120px;
}
</style>
